var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "LBLBASEINFO" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && _vm.isOld
                        ? _c("c-btn", {
                            attrs: { label: "LBLREMOVE", icon: "delete" },
                            on: { btnClicked: _vm.deleteData },
                          })
                        : _vm._e(),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable,
                            expression: "editable",
                          },
                        ],
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.budgetResult,
                          mappingType: _vm.mappingType,
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveData,
                          btnCallback: _vm.saveCallback,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-plant", {
                      attrs: {
                        required: "",
                        disabled: _vm.isOld,
                        editable: _vm.editable,
                        name: "plantCd",
                      },
                      on: {
                        datachange: function ($event) {
                          return _vm.setSumAmtList(false)
                        },
                      },
                      model: {
                        value: _vm.budgetResult.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.budgetResult, "plantCd", $$v)
                        },
                        expression: "budgetResult.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        required: "",
                        disabled: _vm.isOld,
                        default: "today",
                        type: "month",
                        label: "LBL0010332",
                        name: "budgetMonth",
                      },
                      on: {
                        datachange: function ($event) {
                          return _vm.setSumAmtList(false)
                        },
                      },
                      model: {
                        value: _vm.budgetResult.budgetMonth,
                        callback: function ($$v) {
                          _vm.$set(_vm.budgetResult, "budgetMonth", $$v)
                        },
                        expression: "budgetResult.budgetMonth",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-dept", {
                      attrs: {
                        required: "",
                        disabled: _vm.isOld,
                        editable: _vm.editable,
                        label: "LBLDEPT",
                        name: "budgetDeptCd",
                      },
                      model: {
                        value: _vm.budgetResult.budgetDeptCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.budgetResult, "budgetDeptCd", $$v)
                        },
                        expression: "budgetResult.budgetDeptCd",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c("c-table", {
            attrs: {
              title: "항목별 집행금액(누적집행액/예산)",
              columns: _vm.gridPlan.columns,
              data: _vm.divisionPlanItems,
              gridHeightAuto: "",
              columnSetting: false,
              expandAll: false,
              isFullScreen: false,
              usePaging: false,
              hideBottom: true,
              filtering: false,
            },
          }),
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "LBL0010336",
                checkboxDisabled: "checkboxDisabled",
                columns: _vm.grid.columns,
                data: _vm.budgetResult.resultItems,
                gridHeight: "500px",
                editable: _vm.editable,
                columnSetting: false,
                expandAll: false,
                isFullScreen: false,
                usePaging: false,
                hideBottom: true,
                filtering: false,
                rowKey: "budgetResultItemId",
                selection: "multiple",
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "budgetYearPlanItemId"
                        ? [
                            props.row.checkboxDisabled !== "Y"
                              ? _c("c-select", {
                                  attrs: {
                                    stype: "tableselect",
                                    editable: _vm.editable,
                                    disabled: !_vm.editable,
                                    comboItems: _vm.budgetTypeItems,
                                    type: "edit",
                                    itemText: "codeName",
                                    itemValue: "code",
                                  },
                                  on: {
                                    datachange: (val) => _vm.datachange(props),
                                  },
                                  model: {
                                    value: props.row[col.name],
                                    callback: function ($$v) {
                                      _vm.$set(props.row, col.name, $$v)
                                    },
                                    expression: "props.row[col.name]",
                                  },
                                })
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(props.row["budgetTypeItemName"]) +
                                      " "
                                  ),
                                ]),
                          ]
                        : _vm._e(),
                      col.name === "budgetDate"
                        ? [
                            props.row.checkboxDisabled !== "Y"
                              ? _c("c-datepicker", {
                                  staticClass: "tableDatepicker",
                                  attrs: {
                                    dense: "",
                                    type: "date",
                                    disabled: !_vm.editable,
                                    start: _vm.budgetResult.budgetMonth + "-01",
                                    end: _vm.endDate,
                                  },
                                  on: {
                                    datachange: (val) =>
                                      _vm.datachange(props, col),
                                  },
                                  model: {
                                    value: props.row[col.name],
                                    callback: function ($$v) {
                                      _vm.$set(props.row, col.name, $$v)
                                    },
                                    expression: "props.row[col.name]",
                                  },
                                })
                              : _c("span", [
                                  _vm._v(
                                    " " + _vm._s(props.row["budgetDate"]) + " "
                                  ),
                                ]),
                          ]
                        : _vm._e(),
                      col.name === "budgetResultAmt"
                        ? [
                            props.row.checkboxDisabled !== "Y"
                              ? _c("c-number-column", {
                                  attrs: {
                                    editable: _vm.editable,
                                    col: col,
                                    props: props,
                                  },
                                  on: {
                                    datachange: function ($event) {
                                      return _vm.datachange(props, col)
                                    },
                                  },
                                  model: {
                                    value: props.row[col.name],
                                    callback: function ($$v) {
                                      _vm.$set(props.row, col.name, $$v)
                                    },
                                    expression: "props.row[col.name]",
                                  },
                                })
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("toThousandFilter")(
                                          props.row["budgetResultAmt"]
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                          ]
                        : _vm._e(),
                      col.name === "budgetContent"
                        ? [
                            props.row.checkboxDisabled !== "Y"
                              ? _c("c-textarea-column", {
                                  attrs: {
                                    editable: _vm.editable,
                                    col: col,
                                    props: props,
                                  },
                                  on: {
                                    datachange: function ($event) {
                                      return _vm.datachange(props, col)
                                    },
                                  },
                                })
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(props.row["budgetContent"]) +
                                      " "
                                  ),
                                ]),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "LBLADD", icon: "add" },
                            on: { btnClicked: _vm.addItem },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "LBLEXCEPT", icon: "remove" },
                            on: { btnClicked: _vm.deleteItem },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "suffixTitle" },
                [
                  !_vm.isOld
                    ? _c(
                        "font",
                        {
                          staticClass: "text-negative",
                          staticStyle: {
                            "font-size": "0.9em",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm._v(
                            " (신규등록 후에 `3단계판단법`, `순회점검` 예산집행 항목이 표시됩니다.) "
                          ),
                        ]
                      )
                    : _c(
                        "font",
                        {
                          staticClass: "text-negative",
                          staticStyle: {
                            "font-size": "0.9em",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm._v(
                            " (`3단계판단법`, `순회점검` 예산집행은 집행년월 = 조치일, 부서 = 조치부서인 개선의 조치비용이 표시됩니다.) "
                          ),
                        ]
                      ),
                ],
                1
              ),
            ],
            2
          ),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }