<template>
  <div>
    <q-form ref="editForm">
      <!-- 기본정보 -->
      <c-card title="LBLBASEINFO" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <!-- 삭제 -->
            <c-btn 
              v-if="editable && isOld" 
              label="LBLREMOVE" 
              icon="delete"
              @btnClicked="deleteData" />
            <!-- 저장 -->
            <c-btn
              v-show="editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="budgetResult"
              :mappingType="mappingType"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
            <c-plant 
              required
              :disabled="isOld"
              :editable="editable"
              name="plantCd" 
              v-model="budgetResult.plantCd"
              @datachange="setSumAmtList(false)" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <!-- 집행년월 -->
            <c-datepicker
              required
              :disabled="isOld"
              default="today"
              type="month"
              label="LBL0010332"
              name="budgetMonth"
              v-model="budgetResult.budgetMonth"
              @datachange="setSumAmtList(false)" 
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
            <!-- 부서 -->
            <c-dept
              required
              :disabled="isOld"
              :editable="editable"
              label="LBLDEPT"
              name="budgetDeptCd"
              v-model="budgetResult.budgetDeptCd"
            />
          </div>
        </template>
      </c-card>
      <c-table
        title="항목별 집행금액(누적집행액/예산)"
        :columns="gridPlan.columns"
        :data="divisionPlanItems"
        gridHeightAuto
        :columnSetting="false"
        :expandAll="false"
        :isFullScreen="false"
        :usePaging="false"
        :hideBottom="true"
        :filtering="false"
      >
      </c-table>
      <!-- 예산집행 목록 -->
      <c-table
        ref="table"
        title="LBL0010336"
        checkboxDisabled="checkboxDisabled"
        :columns="grid.columns"
        :data="budgetResult.resultItems"
        gridHeight="500px"
        :editable="editable"
        :columnSetting="false"
        :expandAll="false"
        :isFullScreen="false"
        :usePaging="false"
        :hideBottom="true"
        :filtering="false"
        rowKey="budgetResultItemId"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline>
            <!-- 추가 -->
            <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addItem" />
            <!-- 제외 -->
            <c-btn v-if="editable" label="LBLEXCEPT" icon="remove" @btnClicked="deleteItem" />
          </q-btn-group>
        </template>
        <template slot="suffixTitle">
          <font v-if="!isOld" class="text-negative" style="font-size:0.9em;font-weight:500;">
            (신규등록 후에 `3단계판단법`, `순회점검` 예산집행 항목이 표시됩니다.)
          </font>
          <font v-else class="text-negative" style="font-size:0.9em;font-weight:500;">
            (`3단계판단법`, `순회점검` 예산집행은 집행년월 = 조치일, 부서 = 조치부서인 개선의 조치비용이 표시됩니다.)
          </font>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='budgetYearPlanItemId'">
            <c-select
              v-if="props.row.checkboxDisabled!=='Y'"
              stype="tableselect"
              :editable="editable"
              :disabled="!editable"
              :comboItems="budgetTypeItems"
              type="edit"
              itemText="codeName"
              itemValue="code"
              v-model="props.row[col.name]"
              @datachange="val => datachange(props)" />
            <span v-else>
              {{props.row['budgetTypeItemName']}}
            </span>
          </template>
          <template v-if="col.name==='budgetDate'">
            <c-datepicker
              v-if="props.row.checkboxDisabled!=='Y'"
              dense
              class="tableDatepicker"
              type="date"
              :disabled="!editable"
              :start="budgetResult.budgetMonth + '-01'"
              :end="endDate"
              v-model="props.row[col.name]"
              @datachange="val => datachange(props, col)"
            ></c-datepicker>
            <span v-else>
              {{props.row['budgetDate']}}
            </span>
          </template>
          <template v-if="col.name==='budgetResultAmt'">
            <c-number-column
              v-if="props.row.checkboxDisabled!=='Y'"
              :editable="editable"
              :col="col"
              :props="props"
              v-model="props.row[col.name]"
              @datachange="datachange(props, col)"
            />
            <span v-else>
              {{props.row['budgetResultAmt'] | toThousandFilter }}
            </span>
          </template>
          <template v-if="col.name==='budgetContent'">
            <c-textarea-column
              v-if="props.row.checkboxDisabled!=='Y'"
              :editable="editable"
              :col="col"
              :props="props"
              @datachange="datachange(props, col)"
            />
            <span v-else>
              {{props.row['budgetContent']}}
            </span>
          </template>
        </template>
      </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'budget-result-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        budgetResultId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      budgetResult: {
        plantCd: null,
        budgetMonth: '',
        budgetDeptCd: '',
        budgetAllAmt: 0,
        resultItems: [],
      },
      planItems: [],
      gridPlan: {
        columns: [
          {
            name: 'budgetTypeItemName1',
            field: 'budgetTypeItemName1',
            label: '구분 / 항목',
            align: 'left',
            style: 'width: 150px',
            sortable: false,
          },
          { 
            name: 'action1',
            field: 'action1',
            label: '실적',
            child: [
              { 
                name: 'stackAmount1',
                field: 'stackAmount1',
                label: '집행금액',
                align: 'right',
                style: 'width: 100px',
                sortable: false,
                type: 'cost'
              },
              { 
                name: 'budgetPlanAmt1',
                field: 'budgetPlanAmt1',
                label: '예산',
                align: 'right',
                style: 'width: 100px',
                sortable: false,
                type: 'cost'
              },
            ]
          },
          {
            name: 'budgetTypeItemName2',
            field: 'budgetTypeItemName2',
            label: '항목',
            align: 'left',
            style: 'width: 150px',
            sortable: false,
          },
          { 
            name: 'action2',
            field: 'action2',
            label: '실적',
            child: [
              { 
                name: 'stackAmount2',
                field: 'stackAmount2',
                label: '집행금액',
                align: 'right',
                style: 'width: 100px',
                sortable: false,
                type: 'cost'
              },
              { 
                name: 'budgetPlanAmt2',
                field: 'budgetPlanAmt2',
                label: '예산',
                align: 'right',
                style: 'width: 100px',
                sortable: false,
                type: 'cost'
              },
            ]
          },
        ],
      },
      grid: {
        columns: [
        ],
      },
      budgetTypeItems: [],
      endDate: '',
      editable: true,
      isSave: false,
      mappingType: 'POST',
      detailUrl: '',
      yearListUrl: '',
      deleteUrl: '',
      saveUrl: '',
      checkUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.budgetResultId)
    },
    divisionPlanItems() {
      let items = [];
      if (this.planItems && this.planItems.length > 0) {
        let length = this.planItems.length;
        let quotient = Math.floor(length / 2)
        let remainderFlag = (length % 2 > 0)
        quotient = quotient + (remainderFlag ? 1 : 0);
        for (let idx = 0; idx < quotient; idx++) {
          items.push({
            budgetTypeItemName1: this.planItems[idx].budgetTypeItemName,
            stackAmount1: this.planItems[idx].stackAmount,
            budgetPlanAmt1: this.planItems[idx].budgetPlanAmt,
            budgetTypeItemName2: this.planItems[idx + quotient] ? this.planItems[idx + quotient].budgetTypeItemName : '',
            stackAmount2: this.planItems[idx + quotient] ? this.planItems[idx + quotient].stackAmount : '',
            budgetPlanAmt2: this.planItems[idx + quotient] ? this.planItems[idx + quotient].budgetPlanAmt : '',
          })
        }
      }
      return items;
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.budget.result.get.url
      this.yearListUrl = selectConfig.sai.budget.year.plant.url
      this.checkUrl = selectConfig.sai.budget.result.check.url
      this.saveUrl = transactionConfig.sai.budget.result.insert.url
      this.deleteUrl = transactionConfig.sai.budget.result.delete.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.budgetResultId)
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.budgetResult = _result.data
          this.setSumAmtList();
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        this.setSumAmtList();
      }
    },
    setSumAmtList() {
      this.$http.url = this.yearListUrl
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.$store.getters.user.plantCd,
        budgetMonth: this.budgetResult.budgetMonth,
        budgetDeptCd: this.budgetResult.budgetDeptCd,
      }
      this.$http.request((_result) => {
        if (this.budgetResult.budgetMonth) {
          let year = this.budgetResult.budgetMonth.substring(0,4)
          let month = this.budgetResult.budgetMonth.substring(5)
          let day = new Date(year, month, 0).getDate();

          this.endDate = year + '-' + month + '-' + day
        }
        this.$set(this.$data, 'planItems', _result.data.planItems)
        this.setHeader();
      },);
    },
    setHeader() {
      this.budgetTypeItems = [];
      this.$_.forEach(this.planItems, _item => {
        if (_item.imprFlag === 'Y') return
        this.budgetTypeItems.push({
          code: _item.budgetYearPlanItemId,
          codeName: _item.budgetTypeItemName
        })
      })
      this.grid.columns = [
        {
          required: true,
          name: 'budgetYearPlanItemId',
          field: 'budgetYearPlanItemId',
          label: '구분 / 항목',
          align: 'left',
          style: 'width: 200px',
          sortable: false,
          type: 'custom',
        },
        {
          required: true,
          name: 'budgetDate',
          field: 'budgetDate',
          // 집행일
          label: 'LBL0010337',
          align: 'center',
          type: 'custom',
          style: 'width:150px',
          sortable: true,
        },
        { 
          required: true,
          name: 'budgetResultAmt',
          field: 'budgetResultAmt',
          // 예산 집행금액(원)
          label: 'LBL0010338',
          align: 'right',
          style: 'width: 150px',
          sortable: false,
          type: 'custom',
        },
        {
          name: 'budgetContent',
          field: 'budgetContent',
          // 집행내용
          label: 'LBL0010339',
          style: 'width: 350px',
          align: 'left',
          sortable: false,
          type: 'custom',
        },
        {
          name: 'attach',
          field: 'attach',
          // 항목별 첨부
          label: 'LBL0010327',
          align: 'center',
          style: 'width: 250px',
          type: 'attach',
          taskClassCd: 'SAI_BUDGET_RESULT_ITEM',
          keyText: 'budgetResultItemId',
          sortable: false,
        },
      ]
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    addItem() {
      if (!this.budgetResult.resultItems) this.budgetResult.resultItems = [];
      this.budgetResult.resultItems.splice(0, 0, {
        budgetResultId: this.popupParam.budgetResultId,
        budgetResultItemId: uid(),
        budgetYearPlanItemId: null,
        budgetTypeCd: 'SBT0000005',
        budgetDate: this.budgetResult.budgetMonth === this.$comm.getThisMonth() ? this.$comm.getToday() : `${this.budgetResult.budgetMonth}-01`,
        budgetResultAmt: '',
        budgetContent: '',
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    deleteItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.budgetResult.deleteResultItems) this.budgetResult.deleteResultItems = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.budgetResult.deleteResultItems, { budgetYearPlanItemId: item.budgetYearPlanItemId }) === -1) {
              this.budgetResult.deleteResultItems.push(item)
          }
          this.budgetResult.resultItems = this.$_.reject(this.budgetResult.resultItems, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    saveData() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sai.budget.result.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sai.budget.result.insert.url
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.grid.columns, this.budgetResult.resultItems, '예산집행')) {
            this.$http.url = this.checkUrl;
            this.$http.type = 'GET';
            this.$http.param = {
              plantCd: this.budgetResult.plantCd,
              budgetMonth: this.budgetResult.budgetMonth,
              budgetDeptCd: this.budgetResult.budgetDeptCd,
              budgetResultId: this.popupParam.budgetResultId,
            };
            this.$http.request((_result) => {
              if (_result.data > 0 && this.mappingType === 'POST') {
                window.getApp.$emit('ALERT', {
                  title: 'LBLGUIDE', // 안내
                  message: 'MSG0010029',  //동일한 년월의 부서별 예산집행내역이 있습니다.
                  type: 'warning', // success / info / warning / error
                });
              } else {
                window.getApp.$emit('CONFIRM', {
                  title: 'LBLCONFIRM',  // 확인
                  message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
                  // TODO : 필요시 추가하세요.
                  type: 'info', // success / info / warning / error
                  // 확인 callback 함수
                  confirmCallback: () => {
                    this.budgetResult.regUserId = this.$store.getters.user.userId;
                    this.budgetResult.chgUserId = this.$store.getters.user.userId;
                    this.isSave = !this.isSave;
                  },
                  // 취소 callback 함수
                  cancelCallback: () => {
                  },
                });
              }
            },);
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.budgetResultId = result.data
      this.getDetail();
    },
    /* eslint-disable no-unused-vars */ 
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.budgetResultId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
